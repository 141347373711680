import Link from 'next/link';
import styles from './ContactButton.module.css';

const ContactButton = () => {
  return (
    <Link href="/contact" passHref>
      <div
        className={` relative inline-flex items-center justify-center bg-pink-500 lg:bg-[#d3315a] text-white uppercase text-sm md:text-sm px-4 md:px-6 py-2 font-semibold overflow-hidden rounded-lg shadow-none lg:shadow-lg transition-transform duration-300 ${styles.button}`}
      >
        <span className="relative z-10">Contact Us</span>
        <div className={`${styles.hoverEffect} ${styles.before}`}></div>
        <div className={`${styles.hoverEffect} ${styles.after}`}></div>
      </div>
    </Link>
  );
};

export default ContactButton;
