'use client'; // Ensure this component is treated as a client component
import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';

const AwardsGrid = () => {
  const awards = [
    {
      imgSrc:
        '/images/awards/Certificate_Cake_Baking_Excellence_Shabnam_Russo_Platinum_Pudding_Competition_2022.jpeg',
      title: 'Certificate of Cake-Baking Excellence',
      subtitle: 'Awarded to Shabnam Russo',
      description:
        'For outstanding contribution in the Platinum Pudding Competition by Fortnum & Mason.',
    },
    {
      videoSrc:
        '/images/awards/Certificate_Cake_Baking_Excellence_Shabnam_Russo_Platinum_Pudding_Competition_2022.mp4',
      title: 'Platinum Pudding Competition Highlights',
      subtitle: 'Celebration Video',
      description:
        'A highlight of Shabnam Russo’s award-winning experience in the Platinum Pudding Competition.',
    },
    // Add more awards as needed
  ];

  const [toggler, setToggler] = useState(false);
  const [slideIndex, setSlideIndex] = useState(1); // FsLightbox uses 1-based indexing

  // Prepare sources and types arrays for FsLightbox
  const sources = awards.map((award) => award.imgSrc || award.videoSrc);
  const types = awards.map((award) => (award.imgSrc ? 'image' : 'video'));

  return (
    <section className="max-w-screen-xl mx-auto my-16 p-6">
      <h2 className="text-center mb-12">Awards And Accolades</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {awards.map((award, index) => (
          <div key={index} className="flex flex-col items-center text-center">
            {/* Conditionally render image or video based on type */}
            {award.imgSrc ? (
              <img
                src={award.imgSrc}
                alt={award.title}
                width={384}
                height={288}
                className="w-auto object-contain mb-4 cursor-pointer"
                onClick={() => {
                  setSlideIndex(index + 1); // FsLightbox uses 1-based indexing
                  setToggler(!toggler);
                }}
              />
            ) : (
              <video
                src={award.videoSrc}
                controls
                preload="auto"
                width="384"
                height="288"
                className="object-contain mb-4 cursor-pointer"
                onClick={() => {
                  setSlideIndex(index + 1); // FsLightbox uses 1-based indexing
                  setToggler(!toggler);
                }}
              />
            )}
            <div className="p-5 text-left">
              <h4 className="font-semibold mb-2 text-2xl lg:text-4xl">
                {award.title}
              </h4>
              <p className="font-semibold text-gray-500">{award.subtitle}</p>
              <p className="text-sm text-gray-700">{award.description}</p>
            </div>
          </div>
        ))}
      </div>

      {/* FsLightbox Component */}
      <FsLightbox
        toggler={toggler}
        sources={sources}
        types={types}
        slide={slideIndex}
      />
    </section>
  );
};

export default AwardsGrid;
