'use client';
import Image from 'next/image';
import Card from '@/components/Card';

const Ourservices = () => {
  const events = [
    { title: 'Wedding Events', image: '/images/services/bg-wedding.jpg' },
    { title: 'Private Parties', image: '/images/services/bg-parties.jpg' },
    { title: 'Special Events', image: '/images/services/bg-special.jpg' },
    { title: 'Corporate Events', image: '/images/services/bg-corporate.jpg' },
  ];

  return (
    <div>
      <section className="bg-pink-50 py-10 md:py-20 lg:py-24">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 md:gap-6 items-center justify-between">
            <div className="lg:col-span-3">
              <h2 className="font-semibold text-black italic">Our Services</h2>
            </div>
            <div className="lg:col-span-1">
              <div className="mb-8 md:mt-10 hidden lg:block">
                <a
                  href="#"
                  className="bg-colorGray-200 hover:bg-colorGray-400 text-colorGray-800 hover:text-white px-8 py-3 lg:px-12 lg:py-5 text-base md:text-lg lg:text-xl uppercase font-semibold"
                >
                  More Services
                </a>
              </div>
            </div>
          </div>

          <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 justify-items-center">
            {events.map((event, index) => (
              <Card key={index} title={event.title} image={event.image} />
            ))}
          </div>
          <div className="mt-16 text-center block lg:hidden">
            <a
              href="#"
              className="bg-colorGray-200 hover:bg-colorGray-400 text-colorGray-800 hover:text-white px-8 py-3 lg:px-12 lg:py-5 text-base md:text-lg lg:text-xl uppercase font-semibold"
            >
              See More Services
            </a>
          </div>

          {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 md:gap-6 items-start justify-between text-center text-white z-20">
            <div className="relative gradient1 p-6 pt-64 md:pb-14 lg:pt-80 z-20">
              <Image
                src="/images/bg-wedding.jpg"
                alt="Wedding Events"
                fill
                className="absolute inset-0 z-0 object-cover"
                quality={75}
                sizes="(max-width: 600px) 100vw, (min-width: 600px) 50vw"
              />
              <div className="relative z-10">
                <h4 className="mb-0">Wedding Events</h4>
              </div>
              <div className="absolute inset-0 bg-black opacity-30 z-5"></div>
            </div>

            <div className="relative gradient1 p-6 pt-64 md:pb-14 lg:pt-80 z-20">
              <Image
                src="/images/bg-parties.jpg"
                alt="Private Parties"
                fill
                className="absolute inset-0 z-0 object-cover"
                quality={75}
                sizes="(max-width: 600px) 100vw, (min-width: 600px) 50vw"
              />
              <div className="relative z-10">
                <h4 className="mb-0">Private Parties</h4>
              </div>
              <div className="absolute inset-0 bg-black opacity-30 z-5"></div>
            </div>

            <div className="relative gradient1 p-6 pt-64 md:pb-14 lg:pt-80 z-20">
              <Image
                src="/images/bg-special.jpg"
                alt="Special Events"
                fill
                className="absolute inset-0 z-0 object-cover"
                quality={75}
                sizes="(max-width: 600px) 100vw, (min-width: 600px) 50vw"
              />
              <div className="relative z-10">
                <h4 className="mb-0">Special Events</h4>
              </div>
              <div className="absolute inset-0 bg-black opacity-30 z-5"></div>
            </div>

            <div className="relative gradient1 p-6 pt-64 md:pb-14 lg:pt-80 z-20">
              <Image
                src="/images/bg-corporate.jpg"
                alt="Corporate Events"
                fill
                className="absolute inset-0 z-0 object-cover"
                quality={75}
                sizes="(max-width: 600px) 100vw, (min-width: 600px) 50vw"
              />
              <div className="relative z-10">
                <h4 className="mb-0">Corporate Events</h4>
              </div>
              <div className="absolute inset-0 bg-black opacity-30 z-5"></div>
            </div>
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default Ourservices;
