'use client';

import dynamic from 'next/dynamic';
import { useRef } from 'react';
import { motion } from 'framer-motion';
import Image from 'next/image';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Dynamically import react-slick with no SSR
const Slider = dynamic(() => import('react-slick'), { ssr: false });

const Intro = () => {
  const sliderRef = useRef(null);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    arrows: true,
  };

  const images = [
    {
      src: '/images/intro/group-photo.jpg',
      alt: 'Noodles and meatballs',
    },
    {
      src: '/images/intro/Butterfly_Decorated_Cake_with_Floral_Background.jpeg',
      alt: 'Catering Service 1',
    },
    {
      src: '/images/intro/Close_Up_Ras_Malai_Tres_Leches_Cake_with_Pistachio_Rose_Petals.jpeg',
      alt: 'Catering Service 2',
    },
    {
      src: '/images/intro/colorful-vegetable-egg-platter-on-pink-patterned-tablecloth.jpeg',
      alt: 'Catering Service 3',
    },
  ];

  return (
    <section className="bg-white py-0 lg:py-14 overflow-hidden">
      <div className="max-w-screen-xl mx-auto flex flex-col lg:flex-row items-center lg:items-start lg:justify-between">
        {/* Left Side: Text Content */}
        <motion.div
          className="lg:w-1/2 text-center lg:text-left px-4 self-center order-2 lg:order-1"
          initial={{ x: '-100vw', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <div className="text-xl text-colorRed-500 font-semibold mt-8 lg:mt-0 uppercase">
            Experience the Best
          </div>
          <h2 className="text-5xl font-bold text-colorRed-600 mb-8">
            Why Choose Garden Suburb Kitchen?
          </h2>
          <p className="text-base text-colorGray-700 mb-4 leading-loose">
            <strong>Garden Suburb Kitchen</strong>, led by renowned event
            planner and baker <strong>Shabnam Russo</strong>, delivers
            exceptional catering for weddings, corporate events, and social
            gatherings. Shabnam gained fame when her{' '}
            <strong>Rose Falooda Cake</strong> was chosen for{' '}
            <strong>Queen Elizabeth II’s Platinum Jubilee</strong> celebrations.
            Her <strong>MediterrAsian</strong> menus, crafted with over a decade
            of expertise, promise an unforgettable culinary experience.
          </p>
          <p className="text-base text-colorGray-700 mb-4 leading-loose">
            In partnership with Chef <strong>Paola Ciccollini</strong>, Garden
            Suburb Kitchen offers everything from{' '}
            <strong>weekly homemade meals</strong> to{' '}
            <strong>children’s party hampers</strong> and custom event catering.
            Shabnam’s acclaimed creations, like her{' '}
            <strong>Rasmalai Tres Leche Cake</strong>, continue to impress. From
            exceptional food to seamless event planning, we ensure your event is
            unforgettable.
          </p>
        </motion.div>

        {/* Right Side: Image Slider */}
        <motion.div
          className="w-full lg:w-1/2 mt-8 lg:mt-0 px-4 order-1 lg:order-2"
          initial={{ x: '100vw', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <Slider {...sliderSettings} ref={sliderRef}>
            {images.map((image, index) => (
              <div key={index} className="px-2">
                <Image
                  src={image.src}
                  alt={image.alt}
                  width={800}
                  height={400}
                  className="object-cover rounded-lg"
                />
              </div>
            ))}
          </Slider>
        </motion.div>
      </div>
    </section>
  );
};

export default Intro;
