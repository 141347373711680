import(/* webpackMode: "eager" */ "/home/ritesh/webdesign/gardensuburbkitchen/next.js/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ritesh/webdesign/gardensuburbkitchen/next.js/src/app/AwardsGrid.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ritesh/webdesign/gardensuburbkitchen/next.js/src/app/CateringSection.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ritesh/webdesign/gardensuburbkitchen/next.js/src/app/Contactform.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ritesh/webdesign/gardensuburbkitchen/next.js/src/app/Intro.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ritesh/webdesign/gardensuburbkitchen/next.js/src/app/Ourservices.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ritesh/webdesign/gardensuburbkitchen/next.js/src/app/Pressmedia.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ritesh/webdesign/gardensuburbkitchen/next.js/src/app/Slider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ritesh/webdesign/gardensuburbkitchen/next.js/src/components/Testimonials.jsx");
