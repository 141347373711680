'use client';
import { useState } from 'react';
import Image from 'next/image';

const Pressmedia = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openImage = (imgSrc) => {
    setSelectedImage(imgSrc);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      <section className="relative -z-10">
        {/* Background Image */}
        <Image
          src="/images/press/pressmedia-bg.jpg"
          alt="Press Media Background"
          fill
          className="absolute inset-0 z-0 object-cover"
          quality={75}
          sizes="(max-width: 600px) 100vw, (min-width: 600px) 50vw"
        />
        {/* Overlay for content */}
        <div className="relative z-10 bg-black bg-opacity-30 py-10">
          <div className="max-w-screen-2xl mx-5 lg:mx-auto">
            <div className="text-center">
              <h2 className="font-semibold text-white italic text-center">
                Press
              </h2>
            </div>
            {/* Collage Section */}
            <section className="bg-gray-100 overflow-hidden bg-transparent h-[500px]">
              <div className="relative">
                <div
                  className="bg-white absolute top-0 left-0 w-[460px] h-[283px] bg-transparent overflow-hidden border-4 border-slate-400 shadow-lg -rotate-[5deg] cursor-pointer"
                  onClick={() =>
                    openImage(
                      '/images/press/Asian_Connect_Future_Dreams_Breast_Cancer_Charity_Shabnam_Russo.jpeg'
                    )
                  }
                >
                  <Image
                    src="/images/press/Asian_Connect_Future_Dreams_Breast_Cancer_Charity_Shabnam_Russo.jpeg"
                    alt="Asian Connect Future Dreams Breast Cancer Charity Shabnam Russo"
                    fill
                    className="rounded-lg object-contain p-2"
                  />
                </div>
                <div
                  className="bg-white absolute top-0 left-[24rem] w-[200px] h-[383px] bg-transparent overflow-hidden border-4 border-slate-400 shadow-lg rotate-[15deg] cursor-pointer"
                  onClick={() =>
                    openImage(
                      '/images/press/DailyMail_Queens_Jubilee_Platinum_Pudding_Finalists.jpeg'
                    )
                  }
                >
                  <Image
                    src="/images/press/DailyMail_Queens_Jubilee_Platinum_Pudding_Finalists.jpeg"
                    alt="Daily Mail Queens Jubilee Platinum Pudding Finalists"
                    fill
                    className="rounded-lg object-contain p-2"
                  />
                </div>

                <div
                  className="bg-white absolute top-[3rem] left-[36rem] w-[460px] h-[283px] bg-transparent overflow-hidden border-4 border-slate-400 shadow-lg rotate-[5deg] cursor-pointer"
                  onClick={() =>
                    openImage(
                      '/images/press/Facebook_Macmillan_Greater_London_Platinum_Pudding_Competition_Shabnam_Russo.jpeg'
                    )
                  }
                >
                  <Image
                    src="/images/press/Facebook_Macmillan_Greater_London_Platinum_Pudding_Competition_Shabnam_Russo.jpeg"
                    alt="Facebook Macmillan Greater London Platinum Pudding Competition Shabnam Russo"
                    fill
                    className="rounded-lg object-contain p-2"
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      {/* Modal for displaying selected image */}
      {selectedImage && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50"
          onClick={closeModal}
        >
          <div className="relative w-[80%] h-[80%]">
            <Image
              src={selectedImage}
              alt="Selected Press Image"
              fill
              className="rounded-lg object-contain"
            />
            <button
              className="absolute top-4 right-4 text-white text-2xl font-bold"
              onClick={closeModal}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Pressmedia;
