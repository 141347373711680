'use client';

import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { useRef } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// Dynamically import react-slick with no SSR
const Slider = dynamic(() => import('react-slick'), { ssr: false });

const SlickSlider = () => {
  const mainSliderRef = useRef(null);

  const slides = [
    {
      title: 'Premium Service',
      content:
        'Experience Hampstead Garden Suburbs  Exclusive Catering Service',
      src: '/images/slider/garden.jpg',
      link: '#about-us',
      description: 'Learn More',
    },
    {
      title: 'Expert Solutions',
      content: 'Our team of experts delivers solutions that guarantee success.',
      src: '/images/slider/slide1.jpg',
      link: '#about-us',
      description: 'Discover More',
    },
    // {
    //   title: "Tailored Strategies",
    //   content: "We offer custom strategies tailored to your business needs.",
    //   src: "/images/slider/caterers.jpeg",
    //   link: "#about-us",
    //   description: "Find Out More",
    // },
  ];

  const mainSliderSettings = {
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    fade: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="relative">
      {/* Main Slider */}
      <div className="relative h-[560px] lg:h-[680px] overflow-hidden -z-10">
        <Slider {...mainSliderSettings} ref={mainSliderRef} className="z-10">
          {slides.map((slide, index) => (
            <div key={index} className="relative h-[560px] lg:h-[680px] z-10">
              <Image
                src={slide.src}
                alt={slide.title}
                fill
                className="object-cover"
              />
              {/* Overlay */}
              <div className="absolute inset-0 bg-black opacity-25"></div>

              {/* Dynamic slide text and link */}
              <motion.div
                className="absolute bottom-44 lg:bottom-56 mx-5 lg:mx-0 left-0 lg:left-20 max-w-[30rem] uppercase z-20 bg-[rgba(101,67,33,0.6)] p-6 rounded-lg text-center"
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: 'easeOut' }}
              >
                <h2 className="text-2xl lg:text-5xl text-white mb-4 font-serif">
                  {slide.title}
                </h2>
                <div className="text-base mt-4 font-medium text-white mb-10">
                  {slide.content}
                </div>
                <Link
                  href={slide.link}
                  className="bg-red-600 text-white px-5 py-2 lg:px-12 lg:py-4 rounded-none hover:bg-red-700"
                >
                  {slide.description}
                </Link>
              </motion.div>
            </div>
          ))}
        </Slider>
      </div>

      {/* SVG Curve at the Bottom */}
      <div className="absolute w-full bottom-0 " style={{ width: '100%' }}>
        <svg viewBox="0 0 1440 320" className="w-full ">
          <path
            fill="#ffffff"
            d="M0,240C200,280,500,320,720,320C940,320,1240,280,1440,240L1440,320L0,320Z"
          ></path>
        </svg>
      </div>
    </div>
  );
};

// Custom Next Arrow
const NextArrow = ({ onClick }) => (
  <div
    className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 p-2 rounded-full cursor-pointer z-30"
    onClick={onClick}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className="h-6 w-6 text-white"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 5l7 7-7 7"
      />
    </svg>
  </div>
);

// Custom Prev Arrow
const PrevArrow = ({ onClick }) => (
  <div
    className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 p-2 rounded-full cursor-pointer z-30"
    onClick={onClick}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className="h-6 w-6 text-white"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15 5l-7 7 7 7"
      />
    </svg>
  </div>
);

export default SlickSlider;
